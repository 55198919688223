export default function jumpChat() {
  const chat = () => {
    if (process.env.VUE_APP_C_SERVICE === "1") {
      //旧接口
      // zE(function() {
      //   zE.show();
      // });
      //更新的接口(要先去exchange_pc\public\index.html打开Zendesk的脚本注释)
      // window.zE("messenger", "show");
      // liveChat插件开始************************
      // window.LiveChatWidget.call("minimize");
      // liveChat插件结束************************
    }
  };
  const chatActive = () => {
    // liveChat插件开始************************
    // window.LiveChatWidget.call("maximize");
    // liveChat插件结束************************
    // zE.activate()
  };
  return {
    chat,
    chatActive,
  };
}
